import { Controller } from '@hotwired/stimulus';
import { agencyFees } from '../../shared/helpers/agencyFeesHelper';

export default class extends Controller {
  static targets = ['estimationStep',
                    'resultStep',
                    'submit',
                    'agencyFees',
                    'netSellerWithClassicAgency',
                    'netSellerWithHosman',
                    'agencyFeesDiff',
                    'premiumLogo',
                    'priceRef',
                    'firstCategory',
                    'secondCategory',
                    'thirdCategory',
                    'fourthCategory',
                    'input',
                    'category',
                    'yourCommission',
                    'changeCriteria',
                    'container',
                    'noExclusivityPrice'];

  static values = {
    parisRegion: Boolean,
    price: String
  }

  connect = () => {
    if (this.hasPriceValue) {
      this.price = this.priceValue
      this.parisRegion = this.parisRegionValue;
      this.handleAgencyFees()
      this.handleHTMLContent()
      this.handleHTMLDisplay()
    } else {
      this.parisRegion = null;
      this.price = null;
      this.region = null;
      if (this.hasSubmitTarget && !this.submitTarget.disabled) this.submitTarget.disabled = true
    }
  }

  handleChange = (e) => {
    const target = e.currentTarget

    if (target.name === 'region') {
      this.parisRegion = target.value.toLowerCase() === 'paris'
      this.region = target.value.toLowerCase()
    }

    if (target.name === 'price') this.price = target.value
    if (this.parisRegion !== null && this.price !== null) this.submitTarget.disabled = false
  }

  handleSubmit = () => {
    this.handleStepsDisplay()
    this.handleAgencyFees()
    this.handleHTMLContent()
    this.handleHTMLDisplay()
    this.pushDataLayer()
  }

  resetModule = () => {
    if(!this.premiumLogoTarget.classList.contains('vesta-display-none')) this.premiumLogoTarget.classList.add('vesta-display-none')
    this.estimationStepTarget.classList.remove('vesta-display-none')
    this.resultStepTarget.classList.remove('active')
    this.categoryTargets.map(category => {
      if(!category.classList.contains('vesta-display-none')) category.classList.add('vesta-display-none')
    })
    this.inputTargets.map(input => {
      if(input.name === 'propertyKind') input.checked = false
      else input.value = ''
    })
    this.resetSlimSelect()
    this.connect()
  }

  resetSlimSelect = () => {
    const inputs = this.inputTargets.filter(input => input.type === 'select-one')
    inputs.forEach(input => {
      const slimSelectController = this.application.getControllerForElementAndIdentifier(
        input,
        "application--slim-select"
      );
      slimSelectController.destroy()
      slimSelectController.connect()
    })
  }

  handleStepsDisplay = () => {
    this.estimationStepTarget.classList.add('vesta-display-none')
    this.resultStepTarget.classList.add('active')
  }

  handleAgencyFees = () => {
    this.agencyFees = agencyFees(
      this.parisRegion,
      this.price,
      false
    );
  }

  handleHTMLContent = () => {
    this.classicAgencyRate = Number(this.price) <= 100000 ? 6.0 : 4.87
    this.feesWithClassicAgency = Number(this.price) * (this.classicAgencyRate / 100)

    if((Number(this.price) > process.env.PERCENTAGE_TRESHOLD_PARIS && this.parisRegion) || (Number(this.price) > process.env.PERCENTAGE_TRESHOLD_REGIONS && !this.parisRegion)) {
      this.agencyFeesTarget.innerHTML = '2,5%'
    } else {
      this.agencyFeesTarget.innerHTML = this.formatPriceHelper(this.agencyFees)
    }

    this.netSellerWithHosmanTarget.innerHTML = this.formatPriceHelper(Number(this.price) - Number(this.agencyFees))
    this.netSellerWithClassicAgencyTarget.innerHTML = this.formatPriceHelper(Number(this.price) - Number(this.feesWithClassicAgency))
    if(this.hasPriceValue && (this.feesWithClassicAgency - this.agencyFees) < 1000) this.containerTarget.classList.add('vesta-display-none')
    this.agencyFeesDiffTarget.innerHTML = this.formatPriceHelper(this.feesWithClassicAgency - this.agencyFees)
    this.priceRefTarget.innerHTML = this.formatPriceHelper(Number(this.price))
    this.noExclusivityPriceTarget.innerHTML = this.calculateNoExclusivityAddFees(this.price, this.parisRegion)
  }

  handleHTMLDisplay = () => {
    // Premium logo condition
    if ((Number(this.price) > process.env.PREMIUM_START_VALUE_REGIONS && !this.parisRegion) || (Number(this.price) > process.env.PREMIUM_START_VALUE_PARIS && this.parisRegion)) this.premiumLogoTarget.classList.toggle('vesta-display-none')

    // "Strong points" trad condition according to hosman prices steps
    if (Number(this.price) <= 300000) this.firstCategoryTarget.classList.toggle('vesta-display-none')
    else if (Number(this.price) <= process.env.PREMIUM_START_VALUE_REGIONS) this.secondCategoryTarget.classList.toggle('vesta-display-none')
    else if (Number(this.price) <= process.env.PREMIUM_START_VALUE_PARIS) this.thirdCategoryTarget.classList.toggle('vesta-display-none')
    else this.fourthCategoryTarget.classList.toggle('vesta-display-none')
  }

  pushDataLayer = () => {
    // new GA4 tracking plan
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'submit_tariff_estimation',
      'region': this.region,
    })
  }

  formatPriceHelper = (price) => {
    const options = { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 };
    const priceString = price.toLocaleString('fr-FR', options);
    return priceString;
  }

  scrollToAnchor() {
    const anchor = document.querySelector("#title-anchor")
    anchor.scrollIntoView({ behavior: 'smooth' })
  }

  calculateNoExclusivityAddFees(price, parisRegion) {
    if (price <= process.env.PERCENTAGE_TRESHOLD_REGIONS) { return '+ 2000 €' }
    else if (parisRegion && price <= process.env.PERCENTAGE_TRESHOLD_PARIS) { return '+ 4000 €'}
    else { return '+ 0,5%' }
  }
}
